import React, { useContext } from 'react';

import { LangContext } from 'context/LangContext';
import Image from 'components/utils/Image';
import * as style from 'styles/components/merch/item.module.scss';
import TrackableCTA from 'components/utils/TrackableCTA';

const MerchItem = ({ item: { url, image, title, price }, isSecondary = false }) => {
    const { lang } = useContext(LangContext);

    let priceFormated = null;
    if (price[0]?.value && price[0]?.currency) {
        let currency = null;
        switch (price[0].currency) {
            case '€':
                currency = 'EUR';
                break;
            case '$':
                currency = 'USD';
                break;
            case 'kr':
                currency = 'SEK';
                break;
            default:
        }
        const currencyFormat = new Intl.NumberFormat(lang.iso, {
            style: 'currency',
            currency,
        });
        priceFormated = currencyFormat.format(price[0].value);
    }

    return (
        <TrackableCTA
            ctaText={title[lang.translationKey]}
            location="Merch"
            destination={url}
        >
            <a className={style.merchItem} href={url} target="_blank" rel="noreferrer">
                <div className={style.merchItem__img}>
                    <Image image={image} width={420} height={480} />
                </div>
                <div
                    className={`${style.merchItem__text} ${
                        isSecondary ? '' : style.merchItem__text_vertical
                    }`}
                >
                    <div>
                        {title && title[lang.translationKey] && (
                            <h3 className="text-lg text-semibold">{title[lang.translationKey]}</h3>
                        )}
                    </div>
                    <div className={style.merchItem__price}>
                        {priceFormated && (
                            <p
                                className={
                                    isSecondary ? 'text-md text-semibold' : 'text-lg text-semibold'
                                }
                            >
                                {priceFormated}
                            </p>
                        )}
                    </div>
                </div>
            </a>
        </TrackableCTA>
    );
};

export default MerchItem;
