import React, { useState, useEffect } from 'react';

import useSponsorAdsBanner from 'data/queries/useSponsorAdsBanner';
import useSponsorAdsBannerMobile from 'data/queries/useSponsorAdsBannerMobile';
import * as style from 'styles/components/homepage/sponsorAdsBanner.module.scss';
import FormattedMessage from 'components/utils/FormattedMessage';
import TrackableCTA from 'components/utils/TrackableCTA';

const setRandomAd = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
}

const SponsorAdsBanner = () => {
    const sponsorAds = useSponsorAdsBanner();
    const sponsorAdsMobile = useSponsorAdsBannerMobile();

    const [sponsorAd, setRandomAdDesktop] = useState(null);
    const [sponsorAdMobile, setRandomAdMobile] = useState(null);

    useEffect(() => {
        setRandomAdMobile(setRandomAd(sponsorAdsMobile));
        setRandomAdDesktop(setRandomAd(sponsorAds));
    }, [sponsorAds, sponsorAdsMobile]);

    // const fullwidthClass = sponsorAds.length === 1 ? "fullwidth" : "";
    // const fullwidthMobileClass = sponsorAdsMobile.length === 1 ? "fullwidth" : "";

    return (
        <>
            {sponsorAd && (
            <div className={`wrapper  ${style.sponsorAds__desktop}`}>
                <div className={`wrappers ${style.sponsorAds} fullwidth`}>
                    <div>
                        {sponsorAd.showAdMark && (
                            <div className={style.sponsorAds__adMark}>
                                <FormattedMessage id="global.ad" />
                            </div>
                        )}
                        <TrackableCTA
                            ctaText={sponsorAd.alt}
                            location="homepage banner (desktop)"
                            destination={sponsorAd.url}
                        >
                            <a
                                href={sponsorAd.url}
                                target={sponsorAd.external ? '_blank' : null}
                                rel="noreferrer"
                            >
                                <img src={sponsorAd.image.asset.url} alt={sponsorAd.alt} />
                            </a>
                        </TrackableCTA>
                    </div>
                </div>
            </div>
            )}
            {sponsorAdMobile && (
            <div className={`wrapper ${style.sponsorAds__mobile}`}>
                <div className={`wrappers ${style.sponsorAds} fullwidth`}>
                    <div>
                        {sponsorAdMobile.showAdMark && (
                            <div className={style.sponsorAds__adMark}>
                                <FormattedMessage id="global.ad" />
                            </div>
                        )}
                        <TrackableCTA
                            ctaText={sponsorAdMobile.alt}
                            location="homepage banner (mobile)"
                            destination={sponsorAdMobile.url}
                        >
                            <a
                                href={sponsorAdMobile.url}
                                target={sponsorAdMobile.external ? '_blank' : null}
                                rel="noreferrer"
                            >
                                <img src={sponsorAdMobile.image.asset.url} alt={sponsorAdMobile.alt} />
                            </a>
                        </TrackableCTA>
                    </div>
                </div>
            </div>
            )}
        </>
    );
};

export default SponsorAdsBanner;
